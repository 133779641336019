import * as React from 'react';
import Layout from '../components/layout';
import Helmet from 'react-helmet';
import {graphql} from 'gatsby';
import {renderContent} from '../wordpress';

export interface Location {
    href: string;
    origin: string;
    protocol: string;
    host: string;
    hostname: string;
    port: string;
    pathname: string;
    search: string;
    hash: string;
    state?: any;
    key: string;
}

export interface PageContext {
    isCreatedByStatefulCreatePages: boolean;
    id: string;
}

export interface GatsbyNodeProps<T> {
    path: string;
    location: Location;
    uri: string;
    pageContext: PageContext;
    data?: T;
}

export interface WordPressPageMeta {
    _et_pb_use_builder: string;
    _et_pb_old_content: string;
}

export interface WordpressPage {
    comment_status: string;
    date: Date;
    excerpt: string;
    guid: string;
    id: string;
    link: string;
    meta: WordPressPageMeta;
    menu_order: number;
    modified: Date;
    path: string;
    slug: string;
    status: string;
    template: string;
    title: string;
    type: string;
    wordpress_id: number;
    content: string;
}

export interface WPPageData {
    wordpressPage: WordpressPage;
}

export const WPPage: React.FC<GatsbyNodeProps<WPPageData>> = props => {
    const page = props.data.wordpressPage;

    return (
        <Layout>
            <Helmet title={page.title} meta={page.yoast_meta} />
            {renderContent(page.content)}
        </Layout>
    );
};

export const query = graphql`
    query GetWpQuery($id: String) {
        wordpressPage(id: {eq: $id}) {
            comment_status
            date
            excerpt
            guid
            id
            link
            meta {
                _et_pb_use_builder
                _et_pb_old_content
            }
            menu_order
            modified
            path
            slug
            status
            template
            title
            type
            wordpress_id
            content
            yoast_meta {
                content
                property
                name
            }
        }
    }
`;

export default WPPage;
